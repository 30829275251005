import {Col, FlexboxGrid, Panel} from 'rsuite';
import 'rsuite/lib/styles/index.less';

function Loading() {

  return (
    <FlexboxGrid justify="center" align="middle" style={{height: '100vh'}}>
      <FlexboxGrid.Item colspan={24} componentClass={Col} lg={6} md={8} sm={16} xs={20}>
        <Panel shaded style={{backgroundColor: 'rgba(0,0,0,0.5)'}}>
          loading...
        </Panel>
      </FlexboxGrid.Item>
    </FlexboxGrid>
  );
}

export default Loading;
