import ResponsiveNav from '@rsuite/responsive-nav';
import {useTranslation} from 'react-i18next';
import {useHistory, useLocation} from 'react-router';


const Navigation = () => {
  const {t} = useTranslation()
  const location = useLocation()
  const history = useHistory()
  const page = location.pathname.split('/').filter(Boolean)[0];

  return (
    <ResponsiveNav style={{marginBottom: 20}} activeKey={page} onSelect={eventKey => history.push(`/${eventKey}`)}>
      <ResponsiveNav.Item eventKey="registration">{t('registration.title')}</ResponsiveNav.Item>
      <ResponsiveNav.Item eventKey="program">{t('program.title')}</ResponsiveNav.Item>
      <ResponsiveNav.Item eventKey="info">{t('info.title')}</ResponsiveNav.Item>
      <ResponsiveNav.Item eventKey="contact">{t('contact.title')}</ResponsiveNav.Item>
    </ResponsiveNav>
  )
}

export default Navigation
