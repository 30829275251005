import {useTranslation} from 'react-i18next';
import {Col, FlexboxGrid, Panel} from 'rsuite';
import 'rsuite/lib/styles/index.less';
import Navigation from './Navigation';
import Title from './Title';

function Program() {
  const {t} = useTranslation();

  return (
    <FlexboxGrid justify="center" align="middle" style={{height: '100vh', overflow: 'auto'}}>
      <FlexboxGrid.Item colspan={24} componentClass={Col} md={18} sm={24}>
        <Panel shaded style={{
          backgroundColor: 'rgba(0,0,0,0.5)',
          color: 'white',
        }}>
          <Navigation />
          <Title title={t('program.title')} />
          <h3 style={{marginTop: 30}}>{t('program.friday.title')}</h3>
          <p>{t('program.friday.text.0')}</p>
          <h3 style={{marginTop: 30}}>{t('program.saturday.title')}</h3>
          <p><b>{t('program.saturday.text.part1.0')}</b> {t('program.saturday.text.part1.1')}</p>
          <p><b>{t('program.saturday.text.part2.0')}</b> {t('program.saturday.text.part2.1')}</p>
          <p><b>{t('program.saturday.text.part3.0')}</b> {t('program.saturday.text.part3.1')}</p>
          <p><b>{t('program.saturday.text.part4.0')}</b> {t('program.saturday.text.part4.1')}</p>
          <p><b>{t('program.saturday.text.part5.0')}</b> {t('program.saturday.text.part5.1')}</p>
          <p><b>{t('program.saturday.text.part6.0')}</b> {t('program.saturday.text.part6.1')}</p>
          <p><b>{t('program.saturday.text.part7.0')}</b> {t('program.saturday.text.part7.1')}</p>
          <p><b>{t('program.saturday.text.part8.0')}</b> {t('program.saturday.text.part8.1')}</p>
          <p style={{marginLeft: 20}}>{t('program.saturday.text.part8.2')}</p>
          <h3 style={{marginTop: 30}}>{t('program.sunday.title')}</h3>
          <p>{t('program.sunday.text.0')}</p>
        </Panel>
      </FlexboxGrid.Item>
    </FlexboxGrid>
  );
}

export default Program;
