import {createContext, PropsWithChildren, useCallback, useContext, useEffect, useState} from 'react';
import {useRouteMatch} from 'react-router';
import Api, {setAuthorizationCode} from '../Api';

interface UrlParams {
  page: string;
  code: string;
}

const auth = {
  isAuthenticated: false,
  async signin(code: string) {
    try {
      setAuthorizationCode(code);
      await Api.get('authenticated')
      auth.isAuthenticated = true;
      return auth.isAuthenticated;
    } catch (error) {
      setAuthorizationCode(undefined);
      throw error;
    }
  },
  async signout() {
    setAuthorizationCode(undefined);
    auth.isAuthenticated = false;
    return auth.isAuthenticated;
  }
};

/** For more details on
 * `authContext`, `ProvideAuth`, `useAuth` and `useProvideAuth`
 * refer to: https://usehooks.com/useAuth/
 */

interface AuthInterface {
  isAuthenticated: boolean | null;
  signin: (code: string) => Promise<boolean>;
  signout: () => Promise<boolean>;
}

const AuthContext = createContext<AuthInterface>(auth);

export default function AuthProvider({children}: PropsWithChildren<{}>) {
  const auth = useProvideAuth();

  return (
    <AuthContext.Provider value={auth}>
      {children}
    </AuthContext.Provider>
  );
}

export function useAuth() {
  return useContext(AuthContext);
}

function useProvideAuth() {
  const match = useRouteMatch<UrlParams>({
    path: "/:page/:code/",
    strict: false,
    sensitive: true
  });
  const code = match && match.params && match.params.code;
  const [isAuthenticated, setAuthenticated] = useState<boolean | null>(code ? null : false);

  const signin = useCallback(async (code: string) => {
    const authenticated = await auth.signin(code);

    setAuthenticated(authenticated);
    return authenticated;
  }, [setAuthenticated]);

  const signout = useCallback(async () => {
    const authenticated = await auth.signout();

    setAuthenticated(authenticated);
    return authenticated;
  }, [setAuthenticated]);

  useEffect(() => {
    if (match && match.params && match.params.code && !isAuthenticated) {
      signin(atob(match.params.code));
    }
  }, [signin, match, isAuthenticated])

  return {
    isAuthenticated,
    signin,
    signout
  };
}
