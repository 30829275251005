import {useCallback, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {useHistory, useLocation} from 'react-router-dom';
import {Button, ButtonToolbar, Col, FlexboxGrid, Form, FormControl, FormGroup, Panel} from 'rsuite';
import 'rsuite/lib/styles/index.less';
import {useAuth} from '../authentication/AuthProvider';
import {RedirectFromState} from './PrivateRoute';
import Title from './Title';

function Login() {
  const {t} = useTranslation();
  const auth = useAuth();
  const history = useHistory();
  const location = useLocation<RedirectFromState>();
  const {from} = location.state || {from: {pathname: "/"}};
  const [code, setCode] = useState(''); //
  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  const signin = useCallback(async () => {
    try {
      await auth.signin(code);
      setErrorMessage(null);
      history.replace(from);
    } catch (error) {
      setErrorMessage(String(error));
    }
  }, [code, auth, setErrorMessage, from, history]);

  return (
    <FlexboxGrid justify="center" align="middle" style={{height: '100vh'}}>
      <FlexboxGrid.Item colspan={24} componentClass={Col} lg={6} md={8} sm={16} xs={20}>
        <Panel shaded style={{backgroundColor: 'rgba(0,0,0,0.5)'}}>
          <Title title={t('login.title')} />
          <Form fluid>
            <FormGroup>
              <FormControl
                name="code"
                value={code}
                errorMessage={errorMessage}
                placeholder={t("login.placeholder")}
                onChange={setCode}
              />
            </FormGroup>
            <FormGroup>
              <ButtonToolbar>
                <Button appearance="primary" onClick={signin}>{t('login.button')}</Button>
              </ButtonToolbar>
            </FormGroup>
          </Form>
        </Panel>
      </FlexboxGrid.Item>
    </FlexboxGrid>
  );
}

export default Login;
