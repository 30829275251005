// A wrapper for <Route> that redirects to the login

import {PropsWithChildren} from 'react';
import {Redirect, Route, useLocation} from 'react-router-dom';
import {useAuth} from '../authentication/AuthProvider';
import Loading from './Loading';

export interface RedirectFromState {
  from: Location;
}

// screen if you're not yet authenticated.
function PrivateRoute({children, ...rest}: PropsWithChildren<any>) {
  const auth = useAuth();
  const location = useLocation();
  const redirectTo = {
    pathname: '/login',
    state: {
      from: location,
    }
  };

  if (auth.isAuthenticated === true) {
    return <Route {...rest}>{children}</Route>
  } else if (auth.isAuthenticated === null) {
    return <Loading />;
  } else {
    return <Redirect to={redirectTo} />
  }
}

export default PrivateRoute;
