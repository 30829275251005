import {useTranslation} from 'react-i18next';
import {Col, Divider, FlexboxGrid, Panel} from 'rsuite';
import 'rsuite/lib/styles/index.less';
import Navigation from './Navigation';
import Title from './Title';

function Contact() {
  const {t} = useTranslation();

  return (
    <FlexboxGrid justify="center" align="middle" style={{height: '100vh', overflow: 'auto'}}>
      <FlexboxGrid.Item colspan={24} componentClass={Col} md={18} sm={24}>
        <Panel shaded style={{
          backgroundColor: 'rgba(0,0,0,0.5)',
          color: 'white',
        }}>
          <Navigation />
          <Title title={t('contact.title')} />
          <Divider />
          <h3 style={{marginTop: 30}}>{t('contact.contact1')}</h3>
          <b>Manuel Rudin</b>
          <dl>
            <td>Whatsapp</td>
            <dd><a href="tel:+41795391548">+41 79 539 15 48</a></dd>
            <td>E-mail</td>
            <dd><a href="mailto:manuel.rudin@gmail.com">manuel.rudin@gmail.com</a></dd>
          </dl>
          <h3 style={{marginTop: 30}}>{t('contact.contact2')}</h3>
          <b>Fernanda Schmidmeister</b>
          <dl>
            <td>Whatsapp</td>
            <dd><a href="tel:+41792390400">+41 79 239 04 00</a></dd>
            <td>E-mail</td>
            <dd><a href="mailto:fernanda.schmidmeister@gmail.com">fernanda.schmidmeister@gmail.com</a></dd>
          </dl>
        </Panel>
      </FlexboxGrid.Item>
    </FlexboxGrid>
  );
}

export default Contact;
