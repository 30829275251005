import axios from 'axios';

const Api = axios.create({
  baseURL: process.env.NODE_ENV === 'development' ? 'http://localhost:3000/api' : '/api',
  timeout: 1000,
  headers: {
    'content-type': 'application/json'
  }
})
export const setAuthorizationCode = (code?: string) => {
  Api.defaults.headers.Authorization = code;
}


export default Api;
