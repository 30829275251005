interface Props {
  title: string;
}

function Title({title}: Props) {
  return (
    <h1 style={{
      marginBottom: 20,
      color: 'white',
      textAlign: 'left',
    }}>{title}</h1>
  )
}

export default Title;
