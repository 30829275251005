import {Suspense} from 'react';
import {BrowserRouter, Redirect, Route, Switch} from 'react-router-dom';
import LocaleProvider from 'rsuite/lib/IntlProvider';
import 'rsuite/lib/styles/index.less';
import './App.scss';
import AuthProvider from './authentication/AuthProvider';
import Contact from './components/Contact';
import GuestList from './components/GuestList';
import Info from './components/Info';
import Login from './components/Login';
import MainTemplate from './components/MainTemplate';
import PrivateRoute from './components/PrivateRoute';
import Program from './components/Program';
import i18n from './i18n';


function App() {

  return (
    <Suspense fallback="loading">
      <LocaleProvider locale={i18n}>
        <BrowserRouter>
          <AuthProvider>
            <Switch>
              <PrivateRoute path="/guestlist/:code?"><GuestList /></PrivateRoute>
              <Route>
                <MainTemplate>
                  <Switch>
                    <PrivateRoute path="/program/:code?"><Program /></PrivateRoute>
                    <PrivateRoute path="/info/:code?"><Info /></PrivateRoute>
                    <PrivateRoute path="/contact/:code?"><Contact /></PrivateRoute>
                    <Route path="/login"><Login /></Route>
                    <Route path="/"><Redirect to="/program" /></Route>
                  </Switch>
                </MainTemplate>
              </Route>
            </Switch>
          </AuthProvider>
        </BrowserRouter>
      </LocaleProvider>
    </Suspense>
  );
}

export default App;
