import {useCallback, useEffect, useState} from 'react';
import {Checkbox, Container, Table} from 'rsuite';
import 'rsuite/lib/styles/index.less';
import Api from '../Api';

const {Column, HeaderCell, Cell} = Table;

interface Guest {
  id: number;
  name: string;
}

const columns = [
  'id',
  // 'createdAt',
  // 'updatedAt',
  'primaryGuestId',
  'name',
  'email',
  'comments',
  'fridayDinner',
  'sundayBrunch',
  'accomodationHelp',
]

function GuestList() {
  const [data, setData] = useState<Guest[]>([]);
  const [loading, setLoading] = useState(true);
  const [sortColumn, setSortColumn] = useState<string>(columns[0]);
  const [sortType, setSortType] = useState<'asc' | 'desc'>('asc');
  const handleSort = useCallback((column: string, type: typeof sortType) => {
    setSortColumn(column);
    setSortType(type);
  }, [setSortColumn, setSortType]);

  useEffect(() => {
    setLoading(true)
    Api.get<Guest[]>(`/guests?filter[order]=${sortColumn} ${sortType}`)
      .then(response => setData(response.data))
      .catch(console.error)
      .finally(() => setLoading(false))
  }, [setLoading, setData, sortColumn, sortType])
  console.log(data)
  return (
    <Container>
      <Table loading={loading} wordWrap autoHeight={true} data={data} sortColumn={sortColumn} sortType={sortType} onSortColumn={handleSort}>
        {columns.map(column =>
          <Column key={column} flexGrow={1} sortable>
            <HeaderCell>{column}</HeaderCell>
            <Cell dataKey={column}>
              {(rowData: Guest) => {
                const value = rowData[column as keyof Guest];

                switch (typeof value) {
                  case 'boolean': return <Checkbox inline checked={value} />
                  default: return value;
                }
              }}
            </Cell>
          </Column>
        )}
      </Table>
    </Container>
  );
}

export default GuestList;
