import {PropsWithChildren} from 'react';
import {Container, Content} from 'rsuite';
import 'rsuite/lib/styles/index.less';
import backgroundImage from '../assets/images/IMG_6479.jpg';
import '../i18n';


function MainTemplate({children}: PropsWithChildren<{}>) {

  return (
    <Container style={{
      backgroundImage: `url("${backgroundImage}")`,
      backgroundPositionX: 'center',
      backgroundPositionY: 'top',
      backgroundSize: 'cover',
      backgroundRepeat: 'no-repeat',
    }}>
      <Content>

        {children}
      </Content>
    </Container>
  );
}

export default MainTemplate;
