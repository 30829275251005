import {useTranslation} from 'react-i18next';
import {Col, FlexboxGrid, Panel} from 'rsuite';
import 'rsuite/lib/styles/index.less';
import Navigation from './Navigation';
import Title from './Title';

function Info() {
  const {t} = useTranslation();

  return (
    <FlexboxGrid justify="center" align="middle" style={{height: '100vh', overflow: 'auto'}}>
      <FlexboxGrid.Item colspan={24} componentClass={Col} md={18} sm={24}>
        <Panel shaded style={{
          backgroundColor: 'rgba(0,0,0,0.5)',
          color: 'white',
        }}>
          <Navigation />
          <Title title={t('info.title')} />
          <h3 style={{marginTop: 30}}>{t('info.dresscode.title')}</h3>
          <p>{t('info.dresscode.text')}</p>
          <h3 style={{marginTop: 30}}>{t('info.presents.title')}</h3>
          <p>{t('info.presents.text.0')}</p>
          <p>{t('info.presents.text.1')}</p>
          <h4 style={{marginTop: 30}}>IBAN</h4>
          <p>{t('info.presents.iban.0')}</p>
          <p>{t('info.presents.iban.1')}</p>
          <p>{t('info.presents.iban.2')}</p>
          <p>{t('info.presents.iban.3')}</p>
          <h3 style={{marginTop: 30}}>{t('info.allergies.title')}</h3>
          <p>{t('info.allergies.text')}</p>
        </Panel>
      </FlexboxGrid.Item>
    </FlexboxGrid>
  );
}

export default Info;
